import styled from "@emotion/styled";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OTPImg from "../../assets/images/otp-img.jpg";
import InfoCircle from "../../assets/SVGIcon/info-circle.svg";
import Language from "../../components/Language";
import { useAuth } from "../../hooks/useAuth";
import { useAxios } from "../../hooks/useAxios";
import { aesDecrypt, aesEncrypt } from "../../hooks/useLocalStorage";
import styles from "./Login.module.css";

import { EKYC_COMMON_HOST } from "../../root.component";

export const NumberBox = ({
  fieldName,
  no,
  value = "",
  setValue,
  isFailed = false,
  setIsFailed,
  setErrorMessage,
  isMobile = false,
}) => {
  const handleSetValue = (e) => {
    setIsFailed(false);
    setErrorMessage("");
    setValue(e.target.value);
    const nextfield = document.querySelector(
      `input[name=${isMobile ? "mobile-" : ""}otp-number-${no + 1}]`
    );
    if (nextfield !== null && e.target.value !== "") {
      nextfield.focus();
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      const nextfield = document.querySelector(
        `input[name=${isMobile ? "mobile-" : ""}otp-number-${no - 1}]`
      );
      if (nextfield !== null && value === "") {
        nextfield.focus();
      }
    }
    if (e.key === "ArrowRight") {
      const nextfield = document.querySelector(
        `input[name=${isMobile ? "mobile-" : ""}otp-number-${no + 1}]`
      );
      if (nextfield !== null) {
        nextfield.focus();
      }
    }
    if (e.key === "ArrowLeft") {
      const nextfield = document.querySelector(
        `input[name=${isMobile ? "mobile-" : ""}otp-number-${no - 1}]`
      );
      if (nextfield !== null) {
        nextfield.focus();
      }
    }
  };
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "#ffffff",
        border: isFailed ? "1px solid #F04E1B" : "solid #d8d8d8",
        borderRadius: "4px",
        display: "flex",
        width: isMobile ? "41.67px" : "74.67px",
        height: isMobile ? "52px" : "65px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <TextField
        name={fieldName}
        value={value}
        onChange={handleSetValue}
        inputProps={{
          type: "text",
          maxlength: 1,
          inputmode: "numeric",
          style: {
            color: isFailed ? "#F04E1B" : "#333333",
            fontWeight: "Bold",
            fontFamily: "Helvetica",
            textAlign: "center",
          },
        }}
        sx={{
          "& fieldset": {
            border: "none",
            textAlign: "center",
          },
        }}
        onKeyDown={handleKeyDown}
      />
    </Box>
  );
};

export const OTP = () => {
  const userStorage = window.localStorage.getItem("user");
  const user =
    !userStorage || userStorage === "null"
      ? userStorage
      : JSON.parse(aesDecrypt(window.localStorage.getItem("user")));

  const userEkyc = JSON.parse(
    aesDecrypt(window.localStorage.getItem("userEkyc"))
  );

  const language = window.localStorage.getItem("language") || "id";
  const { echo } = Language();
  const { logout } = useAuth();

  const deviceID = window.localStorage.getItem("deviceID");
  const navigate = useNavigate();
  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const [number1, setNumber1] = useState("");
  const [number2, setNumber2] = useState("");
  const [number3, setNumber3] = useState("");
  const [number4, setNumber4] = useState("");
  const [number5, setNumber5] = useState("");
  const [number6, setNumber6] = useState("");
  const [isFailed, setIsFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [stateType, setStateType] = useState(state?.type);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(60);

  const startTimer = () => {
    setIsButtonDisabled(true);
    setTimer(60);

    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        } else {
          clearInterval(intervalId);
          setIsButtonDisabled(false);
          localStorage.removeItem("timerStatus");
          return 0;
        }
      });
    }, 1000);

    localStorage.setItem(
      "timerStatus",
      JSON.stringify({ isButtonDisabled: true, timer: 60 })
    );

    setTimeout(() => {
      clearInterval(intervalId);
      setIsButtonDisabled(false);
      localStorage.removeItem("timerStatus");
    }, 60000);
  };

  useEffect(() => {
    const storedTimerStatus = localStorage.getItem("timerStatus");

    if (storedTimerStatus) {
      const { isButtonDisabled, timer } = JSON.parse(storedTimerStatus);

      if (timer > 0) {
        setIsButtonDisabled(isButtonDisabled);
        setTimer(timer);
        startTimer();
      } else {
        localStorage.removeItem("timerStatus");
        setIsButtonDisabled(false);
      }
    } else {
      setIsButtonDisabled(isButtonDisabled);
      setTimer(timer);
      startTimer();
    }
  }, []);

  const validateOtp = (otp) => {
    if (state?.isAction) {
      useAxios({
        method: "post",
        url: "/validate",
        body: {
          otp,
          method: state?.method,
        },
      }).then((response) => {
        if (response) {
          const { status, data } = response;
          if (status == 200) {
            if (data.resp_code == 0) {
              if (state?.method?.split("_")[0] === "forgot") {
                navigate("/create-pin");
                return;
              }
              navigate("/withdrawal/submit");
            } else {
              setIsFailed(true);
              setErrorMessage(
                language === "id"
                  ? data?.resp_desc?.Indonesian
                  : data?.resp_desc?.English
              );
              setLoading(false);
            }
          } else if (status === 401) {
            alert(echo("session-expired"));
            logout();
            navigate("/login");
            setLoading(false);
          } else {
            const responseError =
              language === "id"
                ? data?.resp_desc?.Indonesian
                : data?.resp_desc?.English;
            alert(responseError || echo("something-went-wrong"));
            setLoading(false);
          }
        }
      });
      return;
    }
    setIsFailed(false);
    const bodyOtp = {
      type: stateType,
      otp,
    };
    useAxios({
      method: "post",
      override: {
        title: "ekyc-validate-otp",
        url: `${EKYC_COMMON_HOST}/validateOtp`,
        token: userEkyc?.token,
        onboardId: `onboard_ekyc_${userEkyc?.onBoardId}`,
        body: bodyOtp,
      },
    }).then((response) => {
      if (response) {
        const { status, data } = response;
        if (status == 200) {
          if (data.resp_code == 0) {
            if (stateType === "email") {
              setStateType("sms");
              startTimer();
              const bodySendSmsOtp = {
                type: "sms",
              };
              if (state?.isUpdateContact) {
                bodySendSmsOtp.phone = userEkyc?.phone;
              }
              useAxios({
                method: "post",
                override: {
                  title: "ekyc-send-sms-otp",
                  url: `${EKYC_COMMON_HOST}/otp`,
                  token: userEkyc?.token,
                  onboardId: `onboard_ekyc_${userEkyc?.onBoardId}`,
                  body: bodySendSmsOtp,
                },
              }).then((response) => {
                if (response) {
                  const { status, data } = response;
                  if (status == 200) {
                    if (data.resp_code == 0) {
                      navigate("/otp", {
                        state: {
                          type: "sms",
                        },
                      });
                      setNumber1("");
                      setNumber2("");
                      setNumber3("");
                      setNumber4("");
                      setNumber5("");
                      setNumber6("");
                      setLoading(false);
                    } else {
                      const responseError =
                        language === "id"
                          ? data.resp_desc.Indonesian
                          : data.resp_desc.English;
                      setIsFailed(true);
                      setErrorMessage(
                        responseError || echo("something-went-wrong")
                      );
                      setLoading(false);
                    }
                  }
                }
              });
            } else {
              userEkyc.step = "verification-result";
              window.localStorage.setItem(
                "userEkyc",
                aesEncrypt(JSON.stringify(userEkyc))
              );
              navigate("/verification-result", {
                state: {
                  isOTPSuccess: true,
                },
              });
            }
          } else {
            setIsFailed(true);
            setErrorMessage(data.resp_desc?.Indonesian);
            userEkyc.step = "verification-result";
            const isThirdFailed = data?.resp_code === 4;
            window.localStorage.setItem(
              "userEkyc",
              aesEncrypt(JSON.stringify(userEkyc))
            );
            navigate("/verification-result", {
              state: {
                isOTPSuccess: false,
                isThirdFailed,
                respDesc: data.resp_desc?.Indonesian,
              },
            });
          }
        } else {
          const responseError =
            language === "id"
              ? data.resp_desc.Indonesian
              : data.resp_desc.English;
          setIsFailed(true);
          setErrorMessage(responseError || echo("something-went-wrong"));
          setLoading(false);
        }
      }
    });
  };

  const resendOtp = () => {
    startTimer();
    if (state?.isAction) {
      const bodyOtp = {};
      if (stateType === "email") {
        bodyOtp.method = "email_otp";
      } else {
        bodyOtp.method = "phone_otp";
      }
      useAxios({
        method: "post",
        url: "/authenticate",
        body: bodyOtp,
      }).then((response) => {
        if (response) {
          const { status, data } = response;
          if (status == 200) {
            alert("OTP telah berhasil dikirim.");
          } else {
            const responseError =
              language === "id"
                ? data.resp_desc.Indonesian
                : data.resp_desc.English;
            alert(responseError || "Sistem mengalami gangguan, mohon coba beberapa saat lagi.");
          }
        }
      });
      return;
    }

    const bodyResend = {
      type: stateType,
    };
    useAxios({
      method: "post",
      override: {
        title: "ekyc-resend-otp",
        url: `${EKYC_COMMON_HOST}/resendOtp`,
        token: userEkyc?.token,
        onboardId: `onboard_ekyc_${userEkyc?.onBoardId}`,
        body: bodyResend,
      },
    }).then((response) => {
      if (response) {
        const { status, data } = response;
        if (status == 200) {
          localStorage.setItem(
            "userEkyc",
            aesEncrypt(JSON.stringify(userEkyc))
          );
          alert("OTP telah berhasil dikirim.");
        } else {
          const responseError =
            language === "id"
              ? data.resp_desc.Indonesian
              : data.resp_desc.English;
          alert(responseError || echo("something-went-wrong"));
        }
      }
    });
  };

  useEffect(() => {
    if (
      number1 !== "" &&
      number2 !== "" &&
      number3 !== "" &&
      number4 !== "" &&
      number5 !== "" &&
      number6 !== ""
    ) {
      setLoading(true);
      const otp = number1 + number2 + number3 + number4 + number5 + number6;
      validateOtp(otp);
    }
  }, [number1, number2, number3, number4, number5, number6]);

  return (
    <>
      <Container
        sx={(theme) => ({
          [theme.breakpoints.down("lg")]: {
            display: "flex",
            flexDirection: "row",
          },
          display: "none",
        })}
        maxWidth={false}
        disableGutters={true}
        minWidth="sm"
      >
        <div className={styles.container}>
          <div className={styles.div}>
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                paddingY: "24px",
                backgroundColor: "#e5eaef",
                [theme.breakpoints.up("md")]: {
                  width: "70vh",
                },
              })}
            >
              <OTPTitle>
                {echo("verification")} <span>{echo("otp")}</span>
              </OTPTitle>
            </Box>
            {loading ? (
              <CircularProgress sx={{ marginTop: 2 }} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "40px",
                }}
              >
                <Typography
                  sx={(theme) => ({
                    textAlign: "center",
                    color: "#000",
                    paddingY: "10px",
                    font: "400 16px/28px Open Sans, sans-serif",
                  })}
                >
                  {stateType === "email"
                    ? `${echo("please-enter-the-otp-sent-to-your-email")} ${
                        state?.isAction
                          ? ": " + user?.email || ""
                          : userEkyc?.email
                      }`
                    : `${echo("please-enter-the-otp-sent-to-your-phone")} ${
                        state?.isAction
                          ? ": " + user?.phone || ""
                          : userEkyc?.phone
                      }`}
                </Typography>
                <Box
                  sx={(theme) => ({
                    alignItems: "flex-start",
                    justifyContent: "center",
                    display: "inline-flex",
                    gap: "4px",
                    flexDirection: "row",
                    [theme.breakpoints.up("md")]: {
                      width: "70vh",
                    },
                  })}
                >
                  <NumberBox
                    value={number1}
                    setValue={setNumber1}
                    fieldName="mobile-otp-number-1"
                    no={1}
                    isFailed={isFailed}
                    setIsFailed={setIsFailed}
                    setErrorMessage={setErrorMessage}
                    isMobile={true}
                  />
                  <NumberBox
                    value={number2}
                    setValue={setNumber2}
                    fieldName="mobile-otp-number-2"
                    no={2}
                    isFailed={isFailed}
                    setIsFailed={setIsFailed}
                    setErrorMessage={setErrorMessage}
                    isMobile={true}
                  />
                  <NumberBox
                    value={number3}
                    setValue={setNumber3}
                    fieldName="mobile-otp-number-3"
                    no={3}
                    isFailed={isFailed}
                    setIsFailed={setIsFailed}
                    setErrorMessage={setErrorMessage}
                    isMobile={true}
                  />
                  <NumberBox
                    value={number4}
                    setValue={setNumber4}
                    fieldName="mobile-otp-number-4"
                    no={4}
                    isFailed={isFailed}
                    setIsFailed={setIsFailed}
                    setErrorMessage={setErrorMessage}
                    isMobile={true}
                  />
                  <NumberBox
                    value={number5}
                    setValue={setNumber5}
                    fieldName="mobile-otp-number-5"
                    no={5}
                    isFailed={isFailed}
                    setIsFailed={setIsFailed}
                    setErrorMessage={setErrorMessage}
                    isMobile={true}
                  />
                  <NumberBox
                    value={number6}
                    setValue={setNumber6}
                    fieldName="mobile-otp-number-6"
                    no={6}
                    isFailed={isFailed}
                    setIsFailed={setIsFailed}
                    setErrorMessage={setErrorMessage}
                    isMobile={true}
                  />
                </Box>
                {isFailed && (
                  <Typography
                    sx={{
                      fontFamily: "Helvetica",
                      fontSize: "14px",
                      fontWeight: 300,
                      lineHeight: "22px",
                      color: "#F04E1B",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      display: "inline-flex",
                      marginTop: 2,
                    }}
                  >
                    <img
                      style={{ marginRight: 4, textAlign: "center" }}
                      src={InfoCircle}
                    />
                    {errorMessage}
                  </Typography>
                )}
              </Box>
            )}
            {/* <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            <Button
              sx={(theme) => ({
                fontStyle: "normal",
                fontFamily: "Open Sans-Bold, Helvetica",
                textTransform: "none",
                display: "flex",
                alignItems: "flex-start",
                alignSelf: "flex-start",
                gap: "10px",
                justifyContent: "center",
                padding: "4px 24px",
                cursor: "pointer",
                borderColor: "#f7f7f7",
                backgroundColor: "#ed1a2d",
                borderRadius: "100px",
                width: clientWidth > 700 ? "40vw" : "75vw",
                "&:hover": {
                  backgroundColor: "#ed1a2d",
                  boxShadow: "0 12px 8px -2px #D3d3d3",
                },
              })}
              className={styles.text_wrapper_5}
              onClick={() => {
                setLoading(true);
                validateOtp();
              }}
            >
              Lanjutkan
            </Button>
          </Box> */}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              <Button
                disabled={isButtonDisabled}
                sx={(theme) => ({
                  fontStyle: "normal",
                  fontFamily: "Open Sans-Bold, Helvetica",
                  textTransform: "none",
                  display: "flex",
                  alignItems: "flex-start",
                  alignSelf: "flex-start",
                  gap: "10px",
                  justifyContent: "center",
                  padding: "4px 24px",
                  cursor: !isButtonDisabled ? "pointer" : "",
                  borderColor: "#f7f7f7",
                  backgroundColor: "#1B365D",
                  borderRadius: "100px",
                  width: "75vw",
                  "&:hover": {
                    backgroundColor: "#1B365D",
                    boxShadow: "0 12px 8px -2px #D3d3d3",
                  },
                  "&:disabled": {
                    backgroundColor: "lightgrey",
                  },
                  [theme.breakpoints.up("sm")]: {
                    width: "40vw",
                  },
                })}
                className={styles.text_wrapper_5}
                onClick={!isButtonDisabled ? resendOtp : null}
              >
                {echo("resend-otp")}
                {" ("}
                {isButtonDisabled ? (
                  <span style={{ color: "red" }}>{timer + echo("s")}</span>
                ) : (
                  "60" + echo("s")
                )}
                {")"}
              </Button>
              {/* <VerifikasiLain>
              <VerfikasiLainText>
                Verifikasi dengan metode lain
              </VerfikasiLainText>
              <IconLainBox>
                <IconLain
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/bb80eb57-25fa-4f18-9e8a-f265f9e40c12?apiKey=b2d053928e754d30be4e4f99aa30b407&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb80eb57-25fa-4f18-9e8a-f265f9e40c12?apiKey=b2d053928e754d30be4e4f99aa30b407&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb80eb57-25fa-4f18-9e8a-f265f9e40c12?apiKey=b2d053928e754d30be4e4f99aa30b407&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb80eb57-25fa-4f18-9e8a-f265f9e40c12?apiKey=b2d053928e754d30be4e4f99aa30b407&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb80eb57-25fa-4f18-9e8a-f265f9e40c12?apiKey=b2d053928e754d30be4e4f99aa30b407&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb80eb57-25fa-4f18-9e8a-f265f9e40c12?apiKey=b2d053928e754d30be4e4f99aa30b407&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb80eb57-25fa-4f18-9e8a-f265f9e40c12?apiKey=b2d053928e754d30be4e4f99aa30b407&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb80eb57-25fa-4f18-9e8a-f265f9e40c12?apiKey=b2d053928e754d30be4e4f99aa30b407&"
                />
              </IconLainBox>
            </VerifikasiLain> */}
            </Box>
          </div>
        </div>
      </Container>

      <Container
        sx={(theme) => ({
          [theme.breakpoints.down("lg")]: {
            display: "none",
          },
        })}
        maxWidth={false}
        disableGutters={true}
        minWidth="sm"
      >
        <Box
          sx={(theme) => ({
            flex: 1,
            backgroundColor: "#f7f7f7",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            paddingBottom: "20vh",
            [theme.breakpoints.up("lg")]: {
              flex: 1,
              backgroundColor: "#f7f7f7",
              alignItems: "center",
              display: "flex",
              paddingTop: 0,
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
              paddingY: "50px",
              backgroundColor: "#e5eaef",
              [theme.breakpoints.up("md")]: {
                width: "95%",
              },
            })}
          >
            <OTPTitle>
              {echo("verification")} <span>{echo("otp")}</span>
            </OTPTitle>
          </Box>
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              [theme.breakpoints.up("lg")]: {
                flex: 3,
                flexDirection: "row",
                justifyContent: "space-between",
                margin: 10,
                gap: 5,
              },
            })}
          >
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                [theme.breakpoints.up("lg")]: {
                  flex: 2,
                  alignItems: "flex-start",
                },
              })}
            >
              <Box
                sx={(theme) => ({
                  alignItems: "flex-start",
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                  [theme.breakpoints.down("lg")]: {
                    alignItems: "left",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    marginTop: "45px",
                  },
                })}
              >
                <Typography
                  sx={(theme) => ({
                    fontWeight: 700,
                    fontSize: "40px",
                    fontFamily: "Open Sans-Regular, Helvetica",
                    lineHeight: "32px",
                    [theme.breakpoints.up("lg")]: {
                      fontSize: "38px",
                      lineHeight: "48px",
                    },
                  })}
                  variant="h4"
                >
                  {echo("enter")}{" "}
                  <span
                    style={{
                      fontWeight: 100,
                      fontSize: "40px",
                      fontFamily: "Open Sans-Regular, Helvetica",
                      lineHeight: "32px",
                    }}
                  >
                    {echo("otp")}
                  </span>
                </Typography>
                <Typography
                  sx={(theme) => ({
                    marginBottom: "20px",
                    width: 450,
                    paddingY: "10px",
                    [theme.breakpoints.down("lg")]: {
                      display: "none",
                    },
                  })}
                >
                  {stateType === "email"
                    ? `${echo("please-enter-the-otp-sent-to-your-email")} ${
                        state?.isAction
                          ? ": " + user?.email || ""
                          : userEkyc?.email
                      }`
                    : `${echo("please-enter-the-otp-sent-to-your-phone")} ${
                        state?.isAction
                          ? ": " + user?.phone || ""
                          : userEkyc?.phone
                      }`}
                </Typography>
              </Box>
              <Box
                sx={(theme) => ({
                  alignItems: "flex-start",
                  display: "inline-flex",
                  gap: "18px",
                  flexDirection: "row",
                  [theme.breakpoints.up("md")]: {
                    width: "36vw",
                    marginBottom: 4,
                  },
                })}
              >
                <NumberBox
                  value={number1}
                  setValue={setNumber1}
                  fieldName="otp-number-1"
                  no={1}
                  isFailed={isFailed}
                  setIsFailed={setIsFailed}
                  setErrorMessage={setErrorMessage}
                />
                <NumberBox
                  value={number2}
                  setValue={setNumber2}
                  fieldName="otp-number-2"
                  no={2}
                  isFailed={isFailed}
                  setIsFailed={setIsFailed}
                  setErrorMessage={setErrorMessage}
                />
                <NumberBox
                  value={number3}
                  setValue={setNumber3}
                  fieldName="otp-number-3"
                  no={3}
                  isFailed={isFailed}
                  setIsFailed={setIsFailed}
                  setErrorMessage={setErrorMessage}
                />
                <NumberBox
                  value={number4}
                  setValue={setNumber4}
                  fieldName="otp-number-4"
                  no={4}
                  isFailed={isFailed}
                  setIsFailed={setIsFailed}
                  setErrorMessage={setErrorMessage}
                />
                <NumberBox
                  value={number5}
                  setValue={setNumber5}
                  fieldName="otp-number-5"
                  no={5}
                  isFailed={isFailed}
                  setIsFailed={setIsFailed}
                  setErrorMessage={setErrorMessage}
                />
                <NumberBox
                  value={number6}
                  setValue={setNumber6}
                  fieldName="otp-number-6"
                  no={6}
                  isFailed={isFailed}
                  setIsFailed={setIsFailed}
                  setErrorMessage={setErrorMessage}
                />
              </Box>
              {isFailed && (
                <Typography
                  sx={{
                    fontFamily: "Helvetica",
                    fontSize: "14px",
                    fontWeight: 300,
                    lineHeight: "22px",
                    color: "#F04E1B",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    display: "inline-flex",
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                >
                  <img
                    style={{ marginRight: 4, textAlign: "center" }}
                    src={InfoCircle}
                  />
                  {errorMessage}
                </Typography>
              )}
              {/* {loading ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={() => {
                  setLoading(true);
                  validateOtp();
                }}
                type="submit"
                sx={(theme) => ({
                  fontStyle: "normal",
                  fontFamily: "Open Sans-Bold, Helvetica",
                  textTransform: "none",
                  display: "flex",
                  alignItems: "flex-start",
                  alignSelf: "flex-start",
                  gap: "10px",
                  justifyContent: "center",
                  padding: "8px 24px",
                  width: "180px",
                  cursor: "pointer",
                  borderColor: "#f7f7f7",
                  backgroundColor: "#ed1a2d",
                  borderRadius: "100px",
                  [theme.breakpoints.down("lg")]: {
                    width: "100%",
                  },
                  "&:hover": {
                    backgroundColor: "#ed1a2d",
                    boxShadow: "0 12px 8px -2px #D3d3d3",
                    [theme.breakpoints.down("lg")]: {
                      width: "100%",
                    },
                  },
                })}
              >
                <Typography
                  sx={{
                    color: "#ffffff",
                    fontFamily: "Open Sans-Bold, Helvetica",
                    fontWeight: "700",
                    fontSize: "16px",
                  }}
                >
                  Lanjutkan
                </Typography>
              </Button>
            )} */}

              <Typography
                sx={{
                  fontSize: "16px",
                  alignSelf: "flex-start",
                  fontWeight: 700,
                  color: isButtonDisabled ? "grey" : "",
                  cursor: !isButtonDisabled ? "pointer" : "",
                }}
                onClick={!isButtonDisabled ? resendOtp : null}
              >
                {echo("resend-otp")}
                {" ("}
                {isButtonDisabled ? (
                  <span style={{ color: "red" }}>{timer + echo("s")}</span>
                ) : (
                  "60" + echo("s")
                )}
                {")"}
              </Typography>
            </Box>
            <Box
              sx={(theme) => ({
                flex: 1,
                display: {
                  xs: "none",
                  lg: "block",
                },
              })}
            >
              <img
                height="500px"
                width="666px"
                style={{
                  width: "666px",
                  height: "500px",
                  objectFit: "cover",
                }}
                src={OTPImg}
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default OTP;

const OTPTitle = styled.div`
  color: var(--Primary-Color-Black, #000);
  font: 700 36px/48px Open Sans, sans-serif;

  span {
    font-weight: 300;
  }
`;

const VerifikasiLain = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 20px;
`;

const VerfikasiLainText = styled.div`
  color: var(--Primary-Black-primary, #000);
  font: 700 14px/24px Open Sans, sans-serif;
`;

const IconLainBox = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto 0;
  padding: 2px;
`;

const IconLain = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 12px;
  stroke-width: 0.966px;
  stroke: var(--Primary-Prudential-red, #ed1b2e);
  border-color: rgba(237, 27, 46, 1);
  border-style: solid;
  border-width: 0;
`;
