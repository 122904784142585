import { useEffect, useState } from "react";

const Language = () => {
  const stringParams = document.location.search;
  const searchParams = new URLSearchParams(stringParams);
  const language = searchParams.get("language");
  const isSharia = searchParams.get("isSharia");
  const [isSyariah] = useState(
    isSharia || window.localStorage.getItem("isSharia") || false
  );
  const [lang] = useState(
    language || window.localStorage.getItem("language") || "id"
  );

  const matrix = {
    konvensional: {
      en: {
        "take-picture": "Taking",
        "of-your-id-card": "ID Card Information",
        "take-ktp-required": <>To protect customer data, we need to take a one-time capture of the ID card document along with a selfie photo for data verification. Please prepare your ID card.</>,
        or: " or",
        "letter-of-residence": "Letter of Residence",
        "please-prepare": "Please prepare",
        "please-make-sure": "Please make sure",
        "ID-KTP": "NRIC",
        "Nomor-telepon": "Active GSM number",
        "Alamat-e-mail": "Active 𝘦-𝘮𝘢𝘪𝘭 address",
        continue: "Continue",
        "photo-of-identity-card-must-be-clearly-read":
          "Taking a photo of the ID card must be clear (good lighting conditions, appropriate photo settings, no light reflections, ID cards are not blurry or damaged)",
        "identity-card-is-active-and-valid":
          "The ID card is still active and valid",
        "identity-card-must-be-original-not-a-copy":
          "The original ID card is not a photocopy or printout",
        "please-make-sure-data-matches-ID":
          "Please make sure the data filled in is in accordance with the ID card.",
        nik: "NIK",
        name: "Name",
        "place-date-of-birth": "Place/Date of Birth",
        address: "Address",
        "RT/RW": "RT/RW",
        "Kel/Desa": "Kel/Desa",
        Kecamatan: "Kecamatan",
        religion: "Religion",
        "marital-status": "Marital Status",
        occupation: "Occupation",
        citizenship: "Citizenship",
        "face-recognition": "Face Recognition",
        "you-are-required-to-take-a-picture-of-your-face":
          "You are following the instructions on your screen",
        "please-make-sure-to-take-off":
          "You are not wearing glasses, hats, or masks",
        "for-an-accurate-result":
          "For good results, make sure you are in good lighting conditions, not backlight and the photo is not shaky (blur)",
        "verification-not-yet-successful": "Verification not yet successful",
        "sorry-your-data-not-verified-yet":
          "Sorry, verification has not been successful, please follow the instructions on the screen according to the instructions. You have 3 chances to repeat, please select try again if you want to continue.",
        "try-again": "Try Again",
        "verification-failed": "Verification Failed",
        "otp-invalid-try-again":
          "Sorry, the OTP you entered is still wrong. You have 3 chances to repeat, please select try again if you want to continue.",
        "sorry-your-verification-failed":
          "The OTP code you entered is not correct. Please check the email address registered in Prudential Indonesia/Prudential Syariah. If you want to change your Contact Data, please download Pulse or contact 1500085 (Prudential Indonesia) or 1500577 (Prudential Syariah)",
        back: "Back",
        finish: "Done!",
        "verification-successful": "Face recognition process successful!",
        "verification-success-continue":
          "Verification has been successful, please click continue.",
        "please-check-your": "Please check your ",
        "your-email-and-phone-number-are-required":
          "Please make sure your registered phone number and email address are still active and you use it for verification purposes",
        "if-there-is-change":
          'If there are changes, please click "Update Contact Information"',
        confirm: "Confirm",
        "provide-your": "Provide your ",
        "contact-information":
          "Data contact registered in Prudential/Prudential Syariah system",
        "Contact-Information": "Contact Information",
        "enter-your-new-email-and-phone-number":
          "Please enter your new phone number and/or email address below.",
        email: "Email",
        "phone-number": "Phone Number",
        "update-contact-information": "Update Contact Information",
        "update-data": "Update Data",
        "contact-information-updated":
          "Data successfully sent and waiting for change process",
        "please-confirm-to-continue": "Please click continue to proceed.",
        verifcation: "Verification ",
        enter: "Enter",
        otp: "OTP",
        "otp-verification": "OTP Verification",
        "apply/transaction": "application/transaction",
        "please-enter-the-otp-sent-to-your-email":
          "Please enter the OTP we sent to your email",
        "please-enter-the-otp-sent-to-your-phone":
          "Please enter the OTP we sent to your phone",
        "resend-otp": "Resend",
        s: "s",
        "enter-the-otp-code-sent-to-your-phone":
          "Enter the OTP code sent to your phone",
        "select-otp-method":
          "Please select one verification method: OTP or Face Recognition to continue your transaction",
        home: "Home",
        welcome: "Welcome,",
        "form-withdrawal": "Withdrawal Form",
        form: "Form",
        "transaction-history": "Transaction History",
        "check-risk-profile": "Check Risk Profile",
        "find-matched-risk-profile":
          "Find out the match of Risk Profile against the insurance products associated with your investment (PAYDI)",
        "you-have": "YOU HAVE ",
        "paydi-policy-insurance":
          "PAYDI Insurance POLICY, please select the desired transaction in the menu above.",
        "not-yet": "No",
        transaction: "Transaction",
        list: "List",
        history: "History",
        withdrawal: "Withdrawal",
        "withdrawal-amount": "Withdraw Amount",
        "nomial-withdrawal-with-25mio-more":
          "Nominal Withdrawal more than or equal to 25 Million must verify with Face Recognition",
        "date-of-withdrawal": "Date of Withdrawal",
        policy: "Policy",
        "policy-no": "Policy No",
        "product-name": "Product Name",
        "find-policy-no": "Find Policy No",
        "policy-holder-data": "Policy Holder Data",
        "policy-holder": "Policy Holder",
        "policy-status": "Policy Status",
        "payment-method": "Payment Method",
        "payment-method-required": "Payment Method is required",
        currency: "Currency",
        close: "Close",
        review: "Review",
        "withdrawal-information": "Withdrawal Information",
        "reason-for-withdrawal": "Reason for Withdrawal",
        "type-of-withdrawal": "Type of Withdrawal",
        "choose-fund-investment": "Choose Fund Investment",
        "fund-choosen": "Fund Choosen",
        "something-went-wrong":
          "Sorry, due to system maintenance, you can try again in a few moments.",
        "session-expired": "Session expired, please login again",
        "reason-for-withdrawal-required": "Reason for withdrawal is required",
        "withdrawal-type-required": "Withdrawal type is required",
        "withdrawal-amount-required": "Withdrawal amount is required",
        "fund-investment-required": "Fund investment is required",
        "premi-required": "Premi is required",
        "bank-information": "Bank Information",
        "bank-invalid": "Invalid bank account",
        "premi-and-bank-required": "Premi and bank account is required",
        "other-reason": "Other Reason",
        "cash-value": "Cash Value Estimation",
        "total-unit": "Total Unit",
        "total-nominal-estimation": "Withdrawal Nominal Estimation",
        "unit-available": "Total Unit Estimation",
        "nominal-available": "Cash Value Estimation",
        "unit-withdrawal": "Unit Withdrawal",
        "nominal-withdrawal": "Nominal Withdrawal",
        "total-withdrawal": "Total Withdrawal",
        "need-cash": "Need Cash",
        "cash-trouble": "Cash Trouble",
        "no-job": "No Job",
        "move-abroad": "Move Abroad",
        "buy-new-policy": "Buy New Prudential Policy",
        "investment-performance": "Investment Performance",
        "cash-value-for-premium": "Cash Value for Premium",
        "unsatisfied-company": "Unsatisfied with Company",
        "unsatisfied-agent": "Unsatisfied with Agent",
        "no-reason": "No Reason",
        "product-not-match": "Product Not Match",
        "long-inactive-policy": "Long Inactive Policy",
        "unsatisfied-investment": "Unsatisfied Investment Result",
        "investment-result": "Investment Result",
        conversion: "Conversion",
        "kids-education": "Kids Education",
        nominal: "Nominal",
        unit: "Unit",
        "premi-payment": "Premi Payment/Contribution",
        "add-new-policy": "Add New Policy",
        "transfer-payment": "Transfer Payment",
        "bank-account": "Bank Account",
        "bank-name": "Bank Name",
        "bank-account-name": "Bank Account Name",
        "bank-status": "Bank Status",
        "confirm-other-bank-account": "Confirm Other Bank Account",
        "use-other-bank-account": "Use Other Bank Account",
        "bank-branch": "Bank Branch",
        "bank-city": "Bank City",
        "bank-currency": "Bank Currency",
        "bank-swift-code": "Bank Swift Code",
        country: "Country",
        "document-information": "Document Information",
        "upload-document-support": "Upload Document Support",
        "document-type": "Document Type",
        "change-document": "Change Document",
        amount: "Amount",
        dob: "Date of Birth Policy Holder",
        login: "Login",
        create: "Create",
        enter: "Enter",
        "security-pin": "Security PIN",
        "your-pin": "Your PIN",
        "6-digit-security-pin": "6 digit PIN Polisku",
        logout: "Logout",
        approval: "Approval",
        "withdrawal-and-projection": "Withdrawal and Cash Value Projection",
        "i-understand-and-understand-the-impact":
          "I understand and understand the impact of Withdrawal can reduce the sustainability of benefits and the Cash Value of the Policy.",
        "agree-and-continue": "Agree and Continue",
        "please-approve-first": "Please approve first",
        "premi-or-contribution": "Premi/Contribution",
        transfer: "Transfer",
        "transfer-and-premi": "Transfer and Premi",
        "id-ktp": "NRIC",
        sim: "SIM",
        passport: "Passport",
        kitas: "KITAS",
        "bank-statement": "Bank Passbook",
        "other-document": "Other Document (Driving License, FRC, Birth Certificate, etc)",
        "policy-book": "Policy Book",
        "missing-policy-book-letter": "Missing Policy Book Letter",
        "amendement-for-withdrawal": "Amendement for Withdrawal",
        "amendement-for-surrender": "Amendement for Surrender",
        "company-identity": "Company Identity",
        "other-company-identity": "Other Company Identity",
        "sk-ministry-company": "SK Ministry Company",
        npwp: "NPWP",
        skdp: "Letter of Domicile Company (SKDP)",
        siup: "Letter of Business License (SIU)",
        "money-report": "Money Report",
        "insurance-explanation": "Insurance Explanation",
        "letter-of-authorization": "Surat Kuasa",
        bca: "BCA",
        IDR: "IDR",
        USD: "USD",
        VALAS: "VALAS",
        "add-other-document": "Add Other Document",
        "choose-document-type-first": "Choose document type first",
        "invalid-data": "Invalid Data",
        "pin-not-match": "PIN doesn't match",
        "minimum-withdrawal-amount": "Minimum withdrawal amount is not met",
        "minimum-remaining-amount": "Minimum remaining amount is not met",
        "total-premi-not-match":
          "Withdrawal amount is not match with withdrawal target",
        "policy-number-same-as-login":
          "Premi Policy number same as login policy number",
        "loading-pdf": "Loading PDF...",
        "loading-data": "Loading data...",
        "risk-profile": "Risk Profile",
        "currency-not-match": "Currency not match",
        "easy-step":
          "Please fill in the data below to continue your transaction.",
      },
      id: {
        "take-picture": "Pengambilan",
        "of-your-id-card": "Informasi KTP",
        "take-ktp-required":
          <>Untuk melindungi data Nasabah, kami perlu melakukan satu kali pengambilan dokumen KTP disertai foto <i>selfie</i> untuk verifikasi data. Silakan menyiapkan KTP Anda.</>,
        or: " atau",
        "letter-of-residence": "Surat Keterangan Kependudukan",
        "please-prepare": "Mohon dapat disiapkan",
        "please-make-sure": "Mohon dapat dipastikan",
        "ID-KTP": "Kartu Tanda Penduduk (KTP)",
        "Nomor-telepon": "Nomor GSM yang masih aktif",
        "Alamat-e-mail": "Alamat 𝘦-𝘮𝘢𝘪𝘭 yang masih aktif",
        continue: "Lanjutkan",
        "photo-of-identity-card-must-be-clearly-read":
          'Pengambilan Foto KTP harus jelas (kondisi pencahayaan yang baik, pengaturan foto yang sesuai, tidak ada pantulan cahaya, KTP tidak "blur" atau rusak)',
        "identity-card-is-active-and-valid": "KTP masih aktif dan berlaku",
        "identity-card-must-be-original-not-a-copy":
          "KTP asli bukan foto copy atau hasil cetakan",
        "please-make-sure-data-matches-ID":
          "Mohon pastikan data yang terisi sudah sesuai dengan KTP.",
        nik: "NIK",
        name: "Nama",
        "place-date-of-birth": "Tempat/Tanggal Lahir",
        "RT/RW": "RT/RW", // 'RT/RW' is a common term in Indonesia, it stands for 'Rukun Tetangga/Rukun Warga'
        "Kel/Desa": "Kel/Desa", // 'Kel/Desa' is a common term in Indonesia, it stands for 'Kelurahan/Desa'
        Kecamatan: "Kecamatan", // 'Kecamatan' is a common term in Indonesia, it stands for 'Kecamatan'
        address: "Alamat",
        religion: "Agama",
        "marital-status": "Status Perkawinan",
        occupation: "Pekerjaan",
        citizenship: "Kewarganegaraan",
        "face-recognition": "Pengenalan Wajah",
        "you-are-required-to-take-a-picture-of-your-face":
          "Anda mengikuti instruksi yang ada pada layar Anda",
        "please-make-sure-to-take-off":
          "Anda tidak menggunakan kacamata, topi, atau masker",
        "for-an-accurate-result":
          "Untuk hasil yang baik, pastikan Anda dalam kondisi pencahayaan yang cukup, tidak backlight dan foto tidak goyang (blur)",
        "verification-not-yet-successful": "Verifikasi belum berhasil",
        "sorry-your-data-not-verified-yet":
          "Mohon maaf, verifikasi belum berhasil mohon ikuti petunjuk pada layar sesuai instruksi. Anda memiliki 3 kesempatan untuk mengulangi, silakan pilih coba lagi apabila ingin melanjutkan.",
        "try-again": "Coba Lagi",
        "verification-failed": "Verifikasi belum berhasil",
        "otp-invalid-try-again":
          "Mohon maaf, OTP yang Anda masukkan masih salah. Anda memiliki 3 kesempatan untuk mengulangi, silakan pilih coba lagi apabila ingin melanjutkan.",
        "sorry-your-verification-failed":
          "Kode OTP yang anda masukkan belum sesuai. Silakan lakukan pengecekan alamat email yang terdaftar di Prudential Indonesia/Prudential Syariah. Jika ingin merubah Data Kontak silakan unduh Pulse atau hubungi 1500085 (Prudential Indonesia) atau 1500577 (Prudential Syariah)",
        back: "Kembali",
        finish: "Selesai!",
        "verification-successful": "Proses perekaman wajah sukses!",
        "verification-success-continue":
          "Verifikasi sudah berhasil, silakan klik lanjutkan.",
        "please-check-your": "Silakan periksa ",
        "contact-information":
          "Data kontak yang terdaftar pada sistem Prudential/Prudential Syariah",
        "Contact-Information": "Informasi data kontak",
        "your-email-and-phone-number-are-required":
          "Silakan pastikan nomor handphone dan alamat email yang terdaftar masih aktif dan anda gunakan untuk keperluan verifikasi.",
        "if-there-is-change":
          'Jika ada perubahan silakan klik menu: "Ubah Informasi Kontak"',
        confirm: "Setuju",
        "provide-your": "Berikan ",
        "enter-your-new-email-and-phone-number":
          "Silakan masukkan nomor handphone dan/atau alamat email yang baru di bawah.",
        email: "Email",
        "phone-number": "Nomor Telepon",
        "update-contact-information": "Ubah Informasi Kontak",
        "update-data": "Perbarui Data",
        "contact-information-updated":
          "Data berhasil dikirim dan menunggu proses perubahan",
        "please-confirm-to-continue":
          "Silakan klik lanjutkan untuk melanjutkan proses.",
        verifcation: "Verifikasi ",
        enter: "Masukkan",
        otp: "OTP",
        "otp-verification": "Verifikasi OTP",
        "apply/transaction": "pengajuan/transaksi",
        "please-enter-the-otp-sent-to-your-email":
          "Silakan masukkan OTP yang kami kirim ke email Anda",
        "please-enter-the-otp-sent-to-your-phone":
          "Masukkan OTP yang kami kirim ke ponsel Anda",
        "resend-otp": "Kirim Ulang",
        s: "s",
        "enter-the-otp-code-sent-to-your-phone":
          "Masukkan kode OTP yang dikirim ke ponsel Anda",
        "select-otp-method":
          "Silakan pilih salah satu metode verifikasi: OTP atau Face Recognition untuk melanjutkan transaksi Anda",
        home: "Beranda",
        welcome: "Selamat Datang,",
        "form-withdrawal": "Formulir Penarikan",
        form: "Formulir",
        "transaction-history": "Riwayat Transaksi",
        "check-risk-profile": "Cek Profil Risiko",
        "find-matched-risk-profile":
          "Cari tahu kecocokan Profil Risiko terhadap produk asuransi yang dikaitkan dengan investasi (PAYDI) Anda",
        "you-have": "ANDA MEMILIKI ",
        "paydi-policy-insurance":
          "POLIS Asuransi PAYDI, silakan memilih transaksi yang diinginkan pada menu di atas.",
        "not-yet": "Tidak ada",
        transaction: "Transaksi",
        list: "Daftar",
        history: "Riwayat",
        withdrawal: "Penarikan Dana",
        "withdrawal-amount": "Jumlah Penarikan",
        "nomial-withdrawal-with-25mio-more":
          "Nominal Penarikan sama dengan atau lebih dari 25 Juta harus verifikasi dengan Face Recognition",
        "date-of-withdrawal": "Tanggal Penarikan",
        policy: "Polis",
        "policy-no": "Nomor Polis",
        "product-name": "Nama Produk",
        "find-policy-no": "Cari Nomor Polis",
        "policy-holder-data": "Data Pemegang Polis",
        "policy-holder": "Nama Pemegang Polis",
        "policy-status": "Status Polis",
        currency: "Mata Uang",
        close: "Tutup",
        "payment-method": "Bentuk Pembayaran",
        "payment-method-required": "Bentuk Pembayaran wajib diisi",
        review: "Lihat",
        "withdrawal-information": "Informasi Penarikan Dana",
        "reason-for-withdrawal": "Alasan Penarikan Dana",
        "type-of-withdrawal": "Tipe Penarikan Dana",
        "choose-fund-investment": "Pilih Dana Investasi",
        "fund-choosen": "Dana yang Dipilih",
        "something-went-wrong":
          "Mohon maaf, dikarenakan sedang ada pemeliharaan sistem, Anda bisa coba kembali dalam beberapa saat ke depan.",
        "session-expired": "Sesi telah berakhir, silakan login kembali",
        "reason-for-withdrawal-required": "Alasan penarikan dana wajib diisi",
        "withdrawal-type-required": "Tipe penarikan dana wajib diisi",
        "withdrawal-amount-required": "Jumlah penarikan dana wajib diisi",
        "fund-investment-required": "Dana investasi wajib diisi",
        "premi-required": "Premi wajib diisi",
        "bank-invalid": "Rekening bank tidak valid",
        "premi-and-bank-required": "Premi dan rekening bank wajib diisi",
        "other-reason": "Alasan Lain",
        "cash-value": "Estimasi Nilai Tunai",
        "total-unit": "Total Unit",
        "total-nominal-estimation": "Estimasi Nominal Penarikan",
        "unit-available": "Estimasi Total Unit",
        "nominal-available": "Estimasi Nilai Tunai",
        "unit-withdrawal": "Unit Penarikan",
        "nominal-withdrawal": "Nominal Penarikan",
        "total-withdrawal": "Total Penarikan",
        "need-cash": "Memerlukan Dana",
        "cash-trouble": "Kesulitan Keuangan",
        "no-job": "Tidak bekerja lagi",
        "move-abroad": "Pindah ke Luar Negeri",
        "buy-new-policy": "Beli Polis baru Prudential",
        "investment-performance": "Performa Investasi",
        "cash-value-for-premium": "Nilai Tunai u/pembayaran Premi",
        "unsatisfied-company": "Tidak puas terhadap Perusahaan",
        "unsatisfied-agent": "Tidak puas terhadap plyn agent",
        "no-reason": "Tidak Mengisi Alasan",
        "product-not-match": "Produk tidak sesuai",
        "long-inactive-policy": "Polis terlalu lama tidak aktif",
        "unsatisfied-investment": "Hasil Investasi tdk memuaskan",
        "investment-result": "Hasil Investasi",
        conversion: "Konversi",
        "kids-education": "Biaya Pendidikan Anak",
        nominal: "Nominal",
        unit: "Unit",
        "premi-payment": "Pembayaran Premi/Kontribusi",
        "add-new-policy": "Tambah Tujuan Polis Lain",
        "transfer-payment": "Pembayaran Transfer",
        "bank-information": "Informasi Data Rekening",
        "bank-account": "Rekening Bank",
        "bank-name": "Nama Bank",
        "bank-account-name": "Nama Pemilik Rekening",
        "bank-status": "Status Bank",
        "confirm-other-bank-account": "Konfirmasi Rekening Bank Lain",
        "use-other-bank-account": "Gunakan Rekening Bank Lain",
        "bank-branch": "Cabang Bank",
        "bank-city": "Kota Bank",
        "bank-currency": "Mata Uang Bank",
        "bank-swift-code": "Kode Bank Swift",
        country: "Negara",
        "document-information": "Informasi Dokumen",
        "upload-document-support": "Unggah Dokumen Pendukung",
        "document-type": "Tipe Dokumen",
        "change-document": "Ganti Dokumen",
        amount: "Jumlah",
        dob: "Tanggal Lahir Pemegang Polis",
        login: "Masuk",
        create: "Buat",
        enter: "Masukkan",
        "security-pin": "PIN Keamanan",
        "your-pin": "PIN Anda",
        "6-digit-security-pin": "6 digit PIN Polisku",
        logout: "Keluar",
        approval: "Persetujuan",
        "withdrawal-and-projection": "Penarikan Dana & Proyeksi Nilai Tunai",
        "i-understand-and-understand-the-impact":
          "Saya mengerti dan memahami dampak Penarikan Dana (Withdrawal) dapat mengurangi keberlangsungan manfaat dan Nilai Tunai Polis.",
        "agree-and-continue": "Setuju dan Lanjutkan",
        "please-approve-first": "Mohon setujui terlebih dahulu",
        "premi-or-contribution": "Premi/Kontribusi",
        transfer: "Transfer",
        "transfer-and-premi": "Transfer dan Premi",
        "id-ktp": "KTP",
        sim: "SIM",
        passport: "Paspor",
        kitas: "KITAS",
        "bank-statement": "Buku Tabungan",
        "other-document": "Dokumen Lain (SIM, KK, Akte Lahir, dll)",
        "policy-book": "Buku Polis",
        "missing-policy-book-letter":
          "Surat Kehilangan Buku Polis Dari Kepolisian",
        "amendement-for-withdrawal": "Amendemen Untuk Penarikan Dana",
        "amendement-for-surrender": "Amenden Untuk Penyerahan",
        "company-identity": "Akta Perusahaan",
        "other-company-identity": "Akta Perusahaan Lainnya",
        "sk-ministry-company": "SK Menteri Perusahaan",
        npwp: "NPWP",
        skdp: "Surat Keterangan Domisili Perusahaan (SKDP)",
        siup: "Surat Izin Usaha (SIU)",
        "money-report": "Laporan Keuangan",
        "insurance-explanation": "Keterangan Asuransi",
        "letter-of-authorization": "Surat Kuasa",
        bca: "BCA",
        IDR: "IDR",
        USD: "USD",
        VALAS: "VALAS",
        "add-other-document": "Tambah Dokumen Lain",
        "choose-document-type-first": "Pilih tipe dokumen terlebih dahulu",
        "invalid-data": "Data Tidak Valid",
        "pin-not-match": "PIN tidak cocok",
        "minimum-withdrawal-amount": "Jumlah penarikan minimum tidak terpenuhi",
        "minimum-remaining-amount": "Jumlah sisa minimum tidak terpenuhi",
        "total-premi-not-match":
          "Nominal penarikan tidak sesuai dengan nominal tujuan penarikan",
        "policy-number-same-as-login":
          "Nomor Polis Premi sama dengan nomor polis login",
        "loading-pdf": "Memuat PDF...",
        "loading-data": "Memuat data...",
        "risk-profile": "Profil Risiko",
        "currency-not-match": "Mata uang tidak sesuai",
        "easy-step":
          "Silakan isi data di bawah ini untuk melanjutkan transaksi Anda.",
      },
    },
    syariah: {
      en: {
        // ... english translations
      },
      id: {
        // ... indonesian translations
      },
    },
  };

  const echo = (pointer) => {
    return matrix[isSharia ? "syariah" : "konvensional"][lang][pointer];
  };

  useEffect(() => {
    localStorage.setItem("language", lang);
  }, [lang]);

  return { echo };
};

export default Language;
